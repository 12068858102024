i {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  &.icon-arrow-down {
    width: rem(9);
    height: rem(8);
    background-image: url("../images/general/arrow_down.png");
  }
  &.icon-play1 {
    width: rem(33);
    height: rem(33);
    background-image: url("../images/general/play.png");
  }
  &.icon-play2 {
    width: rem(9);
    height: rem(8);
    background-image: url("../images/general/play-v2.png");
  }
  &.icon-close-white {
    width: rem(7);
    height: rem(7);
    background-image: url("../images/general/close_white.png");
  }
  &.icon-close {
    width: rem(7);
    height: rem(7);
    background-image: url("../images/general/close.png");
  }
}
