#login-page {
  background-image: url("../images/general/bg.jpg");
  .text-desc {
    letter-spacing: rem(15);
    font-size: rem(22);
    font-family: $futuraCom;
    margin-top: rem(20);
  }

  .logo {
    width: rem(200);
    margin: rem(20) auto;
  }
  .container {
    height: 100%;
    margin-top: 0;

    .main-group {
      position: relative;
      z-index: 1;
      margin-top: rem(-120);
    }
  }

  .overlay-popup {
    position: absolute;
    top: rem(141);
    left: 0;
    width: 100%;
    height: calc(100% - #{rem(141)});
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 2;
  }

  // .btn {
  //   position: absolute;
  //   bottom: rem(25);
  //   left: 0;
  //   width: 100%;
  //   z-index: 3;
  // }
}
