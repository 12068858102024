$text-color: #231f20;
$white: #ffffff;
$black: #000000;
$dark-red: #a10006;
$green: #00a651;
$border-gray: #cdcdcd;
$gray: #868686;
$pink: #f7d2d7;
$soft-pink: #f9dce0;
$navi: #143c6a;
$text-box: #eedfd2;
$gray-light: #f2f2f2;

/*Font weight*/
$light: 200;
$normal: 300;
$book: 400;
$medium: 500;
$bold: 700;
$heavy: 900;

/*brake point*/
/*brake point*/
$mobile: 320px;
$mobile-land: 568px;
$mobile-plus: 667px;
$mobile-plus-big: 736px;
$tablet: 768px;
$tablet-landscape: 1024px;
$laptop: 1244px;
$desktop-screen: 1366px;
$desktop: 1440px;
$desktop-big: 1920px;
