@mixin flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

@mixin flex-center($justify: center) {
  @include flex;
  align-items: center;
  justify-content: $justify;
}

@mixin box-shadows($xas, $yas, $size, $color) {
  -webkit-box-shadow: $xas $yas $size $color;
  -moz-box-shadow: $xas $yas $size $color;
  box-shadow: $xas $yas $size $color;
}

@mixin background-cov($url) {
  background-image: url($url);
  background-size: cover;
  background-position: center;
}

@mixin grid($col, $gap) {
  margin-left: -calc(#{$gap} / #{rem(20)});
  margin-right: -calc(#{$gap} / #{rem(20)});

  &__col {
    width: calc(100% / #{$col});
    margin: 0 calc(#{$gap} / #{rem(20)});
  }
}

@mixin grid_width($col, $gap) {
  width: calc(100% / #{$col});
  margin: 0 calc(#{$gap} / #{rem(20)});
}

@function rem($rem) {
  $rem: (568 * ($rem / 10)) / 1920;
  @return #{$rem}rem;
}

@mixin clearfix() {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
