#main-page {
  background-image: url("../images/general/bg.jpg");
  .container {
    .bread {
      position: relative;
      background-color: $black;
      padding: rem(14) rem(210);
      height: rem(73);
      font-size: rem(20);
      color: $white;
      opacity: 0;
      // left: -100%;
    }

    .instruction {
      padding-top: rem(35);
      font-weight: bold;
    }
  }
}
