* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  width: 100%;
  height: 100%;
}

body {
  font-family: $text-font;
  margin: 0;
  overflow: hidden;
  color: $text-color;
  background-color: #fff;
  font-size: rem(18);
  font-weight: normal;
  text-align: center;
  line-height: 1.2;
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-touch-callout: none;
  /*iOS Safari */
  -webkit-user-select: none;
  /* Safari*/
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

img {
  max-width: 100%;
}
h1 {
  font-size: rem(36);
}

strong {
  font-weight: $bold;
}

#main-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  @include flex-center;
  background-position: center bottom;
  background-size: cover;
  position: relative;
  background-color: $white;
  @media only screen and (max-width: 767px) {
    align-items: flex-start;
    justify-content: center;
  }
}

#wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

#content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}

.container {
  width: 100%;
  overflow: hidden;
  height: calc(100% - #{rem(54)});
  margin: rem(54) auto 0;
  position: relative;
  @include clearfix;
  // &.none-breadcrumb {
  //   height: calc(100% - #{rem(54)});
  //   margin: rem(54) auto 0;
  // }

  // &.full-container {
  //   margin-top: 0;
  //   height: 100%;
  // }
}

.flex-center {
  position: relative;
  width: rem(1440);
  height: 100%;
  max-height: rem(900);

  @media only screen and (max-width: $tablet) {
    margin: 0 auto;
  }
}

#bascule-overlay {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.8);

  img {
    margin: 0 auto;
    position: relative;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    width: 45%;
  }
}

.main-group {
  .menu-item {
    position: absolute;
    top: rem(220);
    left: rem(100);
    cursor: pointer;

    &--2 {
      top: rem(377);
      left: rem(410);
    }

    &--3 {
      top: rem(220);
      left: rem(740);
    }

    &--4 {
      top: rem(377);
      left: rem(1050);
    }

    &__content {
      position: relative;
      background-color: $white;
      width: rem(305);
      height: rem(314);
      transform: rotate(45deg);
      box-shadow: -6px -6px 25px -5px rgba(0, 0, 0, 0.2);
      &--2 {
        box-shadow: 6px 6px 25px -5px rgba(0, 0, 0, 0.2);
      }
      &--3 {
        box-shadow: -6px -6px 25px -5px rgba(0, 0, 0, 0.2);
      }
      &--4 {
        box-shadow: 6px 6px 25px -5px rgba(0, 0, 0, 0.2);
      }
    }

    &__box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      transform: rotate(-45deg);
    }

    &__text {
      margin: rem(-20) auto rem(27);
      text-transform: uppercase;
    }

    &__logo {
      &--1 {
        img {
          width: rem(183);
          height: rem(47);
        }
      }
      &--2 {
        img {
          width: rem(253);
          height: rem(92);
        }
      }
      &--3 {
        img {
          width: rem(269);
          height: rem(56);
        }
      }
      &--4 {
        img {
          width: rem(219);
          height: rem(56);
        }
      }
    }
  }
}
