/*Font Family*/
@font-face {
  font-family: "CenturyGothic";
  src: url("fonts/CenturyGothic.eot");
  src: url("fonts/CenturyGothic.eot?#iefix") format("embedded-opentype"),
    url("fonts/CenturyGothic.woff2") format("woff2"),
    url("fonts/CenturyGothic.woff") format("woff"),
    url("fonts/CenturyGothic.ttf") format("truetype"),
    url("fonts/CenturyGothic.svg#CenturyGothic") format("svg");
  font-weight: $normal;
  font-style: $normal;
}

@font-face {
  font-family: "CenturyGothic";
  src: url("fonts/CenturyGothic-Bold.eot");
  src: url("fonts/CenturyGothic-Bold.eot?#iefix") format("embedded-opentype"),
    url("fonts/CenturyGothic-Bold.woff2") format("woff2"),
    url("fonts/CenturyGothic-Bold.woff") format("woff"),
    url("fonts/CenturyGothic-Bold.ttf") format("truetype"),
    url("fonts/CenturyGothic-Bold.svg#CenturyGothic-Bold") format("svg");
  font-weight: $bold;
  font-style: $bold;
}

@font-face {
  font-family: "Futura Com";
  src: url("fonts/hinted-FuturaCom-Light.eot");
  src: url("fonts/hinted-FuturaCom-Light.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/hinted-FuturaCom-Light.woff2") format("woff2"),
    url("fonts/hinted-FuturaCom-Light.woff") format("woff"),
    url("fonts/hinted-FuturaCom-Light.ttf") format("truetype"),
    url("fonts/hinted-FuturaCom-Light.svg#FuturaCom-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Futura Com";
  src: url("fonts/hinted-FuturaCom-LightOblique.eot");
  src: url("fonts/hinted-FuturaCom-LightOblique.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/hinted-FuturaCom-LightOblique.woff2") format("woff2"),
    url("fonts/hinted-FuturaCom-LightOblique.woff") format("woff"),
    url("fonts/hinted-FuturaCom-LightOblique.ttf") format("truetype"),
    url("fonts/hinted-FuturaCom-LightOblique.svg#FuturaCom-LightOblique")
      format("svg");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Futura Com";
  src: url("fonts/hinted-FuturaCom-Book.eot");
  src: url("fonts/hinted-FuturaCom-Book.eot?#iefix") format("embedded-opentype"),
    url("fonts/hinted-FuturaCom-Book.woff2") format("woff2"),
    url("fonts/hinted-FuturaCom-Book.woff") format("woff"),
    url("fonts/hinted-FuturaCom-Book.ttf") format("truetype"),
    url("fonts/hinted-FuturaCom-Book.svg#FuturaCom-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Futura Com";
  src: url("fonts/hinted-FuturaCom-BookOblique.eot");
  src: url("fonts/hinted-FuturaCom-BookOblique.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/hinted-FuturaCom-BookOblique.woff2") format("woff2"),
    url("fonts/hinted-FuturaCom-BookOblique.woff") format("woff"),
    url("fonts/hinted-FuturaCom-BookOblique.ttf") format("truetype"),
    url("fonts/hinted-FuturaCom-BookOblique.svg#FuturaCom-BookOblique")
      format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Futura Com";
  src: url("fonts/hinted-FuturaCom-Medium.eot");
  src: url("fonts/hinted-FuturaCom-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/hinted-FuturaCom-Medium.woff2") format("woff2"),
    url("fonts/hinted-FuturaCom-Medium.woff") format("woff"),
    url("fonts/hinted-FuturaCom-Medium.ttf") format("truetype"),
    url("fonts/hinted-FuturaCom-Medium.svg#FuturaCom-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Futura Com";
  src: url("fonts/hinted-FuturaCom-MediumOblique.eot");
  src: url("fonts/hinted-FuturaCom-MediumOblique.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/hinted-FuturaCom-MediumOblique.woff2") format("woff2"),
    url("fonts/hinted-FuturaCom-MediumOblique.woff") format("woff"),
    url("fonts/hinted-FuturaCom-MediumOblique.ttf") format("truetype"),
    url("fonts/hinted-FuturaCom-MediumOblique.svg#FuturaCom-MediumOblique")
      format("svg");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Futura Com";
  src: url("fonts/hinted-FuturaCom-Bold.eot");
  src: url("fonts/hinted-FuturaCom-Bold.eot?#iefix") format("embedded-opentype"),
    url("fonts/hinted-FuturaCom-Bold.woff2") format("woff2"),
    url("fonts/hinted-FuturaCom-Bold.woff") format("woff"),
    url("fonts/hinted-FuturaCom-Bold.ttf") format("truetype"),
    url("fonts/hinted-FuturaCom-Bold.svg#FuturaCom-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Futura Com";
  src: url("fonts/hinted-FuturaCom-BoldOblique.eot");
  src: url("fonts/hinted-FuturaCom-BoldOblique.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/hinted-FuturaCom-BoldOblique.woff2") format("woff2"),
    url("fonts/hinted-FuturaCom-BoldOblique.woff") format("woff"),
    url("fonts/hinted-FuturaCom-BoldOblique.ttf") format("truetype"),
    url("fonts/hinted-FuturaCom-BoldOblique.svg#FuturaCom-BoldOblique")
      format("svg");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Futura Com";
  src: url("fonts/hinted-FuturaCom-Heavy.eot");
  src: url("fonts/hinted-FuturaCom-Heavy.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/hinted-FuturaCom-Heavy.woff2") format("woff2"),
    url("fonts/hinted-FuturaCom-Heavy.woff") format("woff"),
    url("fonts/hinted-FuturaCom-Heavy.ttf") format("truetype"),
    url("fonts/hinted-FuturaCom-Heavy.svg#FuturaCom-Heavy") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Futura Com";
  src: url("fonts/hinted-FuturaCom-HeavyOblique.eot");
  src: url("fonts/hinted-FuturaCom-HeavyOblique.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/hinted-FuturaCom-HeavyOblique.woff2") format("woff2"),
    url("fonts/hinted-FuturaCom-HeavyOblique.woff") format("woff"),
    url("fonts/hinted-FuturaCom-HeavyOblique.ttf") format("truetype"),
    url("fonts/hinted-FuturaCom-HeavyOblique.svg#FuturaCom-HeavyOblique")
      format("svg");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Romain BP Text";
  src: url("fonts/hinted-RomainBPTextRegular.eot");
  src: url("fonts/hinted-RomainBPTextRegular.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/hinted-RomainBPTextRegular.woff2") format("woff2"),
    url("fonts/hinted-RomainBPTextRegular.woff") format("woff"),
    url("fonts/hinted-RomainBPTextRegular.ttf") format("truetype"),
    url("fonts/hinted-RomainBPTextRegular.svg#RomainBPTextRegular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

/*Create font variable */
$text-font: "Romain BP Text";
$century: "CenturyGothic";
$futuraCom: "Futura Com";
