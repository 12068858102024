.nav {
  display: none;
  position: absolute;
  width: 100%;
  z-index: 99;
  margin: 0;
  height: rem(54);
  // border-bottom: 1px solid #b2b2b2;
  background-color: $white;

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    @include flex;
    align-items: center;
    justify-content: space-between;
  }

  .group-menu {
    width: 100%;
    @include flex;
    justify-content: flex-end;
    padding-right: rem(25);
  }

  &__access,
  &__users,
  &__logout {
    cursor: pointer;
  }

  &__access,
  &__users,
  &__logout {
    display: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 0.35rem;
    margin-left: rem(30);
    &--btn {
      img {
        margin-right: 0.2rem;
        width: rem(20);
      }
    }

    img,
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__access {
    &--btn {
      img {
        width: rem(5);
      }
    }
  }
}
