.btn {
  position: relative;
  @include flex-center;
  flex-direction: column;
  &__inner {
    @include flex-center;
    min-width: rem(380);
    text-transform: uppercase;
    color: $white;
    background-color: $black;
    padding: rem(20);
    letter-spacing: rem(4);
    cursor: pointer;
    &.white {
      background-color: $white;
      color: $black;
      border: 1px solid $black;
    }
  }
}
