#intro-page {
  background-image: url("../images/general/bg.jpg");
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    height: auto;
    .group-log {
      margin: rem(125) auto rem(118);
      img {
        width: rem(311);
        height: rem(135);
      }
    }
    .ins {
      position: relative;
      padding: 0 rem(309) rem(125);
      line-height: rem(27);
      font-weight: $medium;
    }
  }
}
