@media screen and (min-width: 1024px) {
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    // display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #666;
  }
}
